<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="直推前第一、二个会员分别返佣">
              <el-input-number v-model="form.first_or_seconds_award"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="直推非第一、二个会员奖励">
              <el-input-number v-model="form.direct_not_fs"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="其它团队奖励">
              <el-input-number v-model="form.root_award"></el-input-number>
              元
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>


export default {
  name: "rule",
  data() {
    return {
      form: {
        first_or_seconds_award: 0,
        direct_not_fs:0,
        root_award: 0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.middleMan.rule().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.card.plugin.middleMan.ruleEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>